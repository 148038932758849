.mainContainer {
    max-width: 1500px;
    width: 100%;
    text-align: left;
    background-color: white;
    padding: 25px;
    border-radius: 25px;
    margin-bottom: 25px;
}

.fieldsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.subtitle:last-of-type {
    margin-top: 45px;
}

.submitButton {
    background-color: #29377D !important;
    margin: 75px 0 !important;
}

.textField {
    background-color: white;
    width: 404px;
    margin-top: 15px !important;
    margin-right: 40px !important;
}

@media screen and (max-width: 1600px) {
    .mainContainer {
        max-width: 1000px;
        background-color: white;
        padding: 25px;
        border-radius: 25px;
        margin-bottom: 25px;
    }
} 
