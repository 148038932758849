.productImage {
    height: 150px;
    width: 150px;
}

.itemText {
    margin-left: 30px;
}

.itemQuantity {
    margin-left: 30px !important;
    margin-right: 30px !important;
    max-width: 80px !important;
}