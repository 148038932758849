.lodingContainer {
    height: 100%;
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000000 !important;
    /* background-color: rgba(0, 0, 0, 0.7); */
    display: flex;
    justify-content: center;
    align-items: center;
}
