@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');
.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.container {
    margin-top: -265px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fourOfour {
    font-size: 150px;
    margin: 0;
    color: #29377D;
    font-family: 'Roboto Mono', monospace;
}

.text {
    font-size: 30px;
    margin: 10px;
    font-family: 'Roboto Mono', monospace;
}