.mainContainer {
    max-width: 664px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left;
}

.text {
    margin: 0;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    width: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis !important;
}

.hide {
    display: none;
}

.orderImage {
    width: 187px;
    height: 166px;
}

.productTexts {
    margin-left: 30px;
}

.productText {
    width: 200px;
    margin: 0;
    color: rgba(0, 0, 0, 0.54);
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis !important;
    text-align: left ;
}

.chipType {
    margin-left: 10px !important ;
}

.chips {
    margin-top: 16px !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.errorIcon {
    margin-right: 16px !important;
}