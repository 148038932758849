.gridLayout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
    overflow: auto;
}

.seeItAll {
    grid-column: 1 / span 3;
}

@media screen and (max-width: 1800px) {
    .gridLayout {
        display: grid;
        grid-template-columns: 450px 450px !important;
        overflow: auto;
    }

    .seeItAll {
        grid-column: 1 / span 2;
    }
}