.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerContainer {
    max-width: 1500px;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

@media screen and (max-width: 1600px) {
    .headerContainer {
        max-width: 1000px;
    }
}