.mainCointainer {
    width: 845px;
    display: flex;
    flex-direction: column;
    padding: 14px;
}

.mainTitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
}

.formContainer {
    display: flex;
    flex-direction: column;
}

.secondaryTitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 31.5px;
    letter-spacing: 0.15px;
}

.textField {
    margin: 8px 0 !important;
}

.divider {
    margin-top: 8px !important;
}

.button {
    background-color: #29377D !important;
}