.mainContainer {
    max-width: 800px;
    margin: auto !important;
}

.title {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.table {
    margin-bottom: 50px;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0;
}

.button {
    text-transform: capitalize !important;
}

.TitleButtonGroup {
    display: flex;
    align-items: flex-end;
}