.tableContainer {
    max-width: 1000px;
    margin-bottom: 50px;
}

.spinner {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}