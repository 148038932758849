.maincontainer {
    width: 256px !important;
}
.navLink {
    text-decoration: none;
    color: black;
}

.navLinkActive {
    text-decoration: none;
    color: #1976D2;
    background-color: aqua;
}

.navLinkActive .buttonIconColor {
    color: #1976D2 !important;
}

.navLinkActive .buttonBackground {
    background-color: #E3EEFA !important;
}