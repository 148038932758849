.mainCointainer {
    width: 408px;
    display: flex;
    flex-direction: column;
    padding: 14px;
}

.mainTitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-top: 8px !important;
}

.textField {
    margin: 8px 0 !important;
}

.button {
    margin-top: 8px;
    background-color: #29377D !important;
}