.outerContainer {
    width: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.mainContainer {
    width: 100%;
    height: 100%;
    
}

.gridLayout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
}

/* .gridLayout {
    display: flex;
    flex-wrap: wrap;
} */

.seeItAll {
    grid-column: 1 / span 3;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 32px 0;
}

.title {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

/* .boxFlex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
} */


@media screen and (max-width: 1800px) {
    .outerContainer {
        width: 1000px !important;
        margin: 0 auto;
    }

    .gridLayout {
        display: grid !important;
        grid-template-columns: 470px 470px !important;
    }
}

