.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 200px;
}

.logoImage {
    height: 200px;
    width: 200px;
    margin: 50px 0 100px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.card {
    width: 408px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.button {
    background-color: #29377D !important;
    margin: 8px 0 !important;
    text-decoration: none !important;
}

.textField {
    margin: 8px 0 !important;
}

.switch {
    margin: 8px 0 !important;
}

.link {
    text-decoration: none;
}

.box {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.switchButton {
    color: red;
}