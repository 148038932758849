.dashboardMainContainer {
    background-color: #F3F4F6;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    width: 100%;
}

.box {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolBar {
    display: flex;
    justify-content: space-between;
}

.appBar {
    background-color: #29377D !important;
}

.sideBar {
    /* padding-top: 65px; */
    background-color: #FFFFFF;
    height: 100vh;
    width: 256px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    
}

.sideBar2 {
    background-color: #FFFFFF;
    height: 100vh;
    width: 256px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    position: fixed;
}


.headerSideBar {
    padding-top: 65px;
}

.logoContainer {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
}

.logoImage {
    height: 44px;
    width: 44px;
    padding: 16px 0;
}

.logoText {
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: 0.15px;
    padding-left: 8px;
} 

.mainContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.sharedContainer {
    padding-top: 100px;
    flex-grow: 1;
    text-align: center;
}

.profileIcon {
    color: #FFFFFF;
}
